.circle-annotation {
    text-align: center;
    line-height: 32px;
    border-width: 1px;
  }
  
  .image-size {
    width: 25px;
    /* height: 70px; */
    top: -50px;
    left: -4px;
    position: absolute;
  }

  .distance-text {
    font-family: sans-serif;
      color: #FFF;
      position: absolute;
      top: -20px;
      left: -7px;
      font-size: 13px;
  }

  .distance-text-larger {
    font-family: sans-serif;
    color: #FFF;
    position: absolute;
    font-weight:600;
    top: -23px;
    left: -9px;
    font-size: 17px;
}

  .currentIndicator {
      width: 15px;
      height: 15px;
      border-radius: 50%;
      color: blue;
      background-color: rgb(109, 109, 228);
      border-width: 2px;
      border-style: solid;
      border-color: #FFF;
  }